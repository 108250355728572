.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.LoginBg {
  background-color: #343a40;
  height: 100vh; }

.cardLogin {
  max-width: 25rem; }

.loginBtn {
  margin-top: 40px; }

.loginPadding {
  padding-top: 65px; }
