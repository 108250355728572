@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
.LoginBg {
  background-color: #343a40;
  height: 100vh; }

.cardLogin {
  max-width: 25rem; }

.loginBtn {
  margin-top: 40px; }

.loginPadding {
  padding-top: 65px; }
.left-position{
  float: left;
  margin-right:30px;
}
.dropdown-height {
  height: calc(2.5rem + 2px)!important;
}
.add-button{
  background-color: lightgreen;
}
.red{
  background-color:#dc3545!important;
  color: white;
}
.green{
  background-color:#28a745!important;
  color: white;
}
.yellow{
  background-color: yellow;
}
.max200-px{
  max-width: 210px;
  margin-bottom: 20px;
}
.bold-text{
  font-weight: 600;
}
.docElement{
  max-width: 42%;
  height: 50px;
  font-size: 21px;
  font-weight: 600;
}
.docElement span {
  width:20%;
}
.right-position{
  float: right;
}
main {
  float: right;
  max-width: 100%;
  margin-top: 58px;
  width: 100%; }
  main .PageContent {
    background-color: #e9ecef;
    max-width: 98%;
    margin: auto;
    margin-top: 10px;
    padding: 0.75rem 1rem; }
  main .fa {
    color: #007bff;
    cursor: pointer;
    padding: 0 1px; }
  main .sort-direction {
    display: inline-block;
    height: 0;
    width: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    cursor: pointer; }
  main .asc {
    border-bottom: 5px solid black; }
  main .desc {
    border-top: 5px solid black; }

.SideDrawer:hover main {
  max-width: 95% !important; }

.hidden {
  display: none; }

.viewTableStyle {
  max-width: 120px; }

.redStar {
  color: red; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield; }

.input-width{
  max-width: 248px;

}

tbody td {
  word-wrap: break-word;
  white-space: normal; }

@media (min-width: 1690px) {
  main {
    max-width: 100%; } }
.user-table th {
  cursor: pointer;
  color: #007bff; }
.user-table td {
  max-width: 100px;
  word-wrap: break-word; }

#page-numbers {
  list-style: none;
  display: flex; }

#page-numbers > li {
  margin-right: 0.3em;
  color: blue;
  user-select: none;
  cursor: pointer; }

.loginTytle {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529; }

.inputLabel {
  width: 100%; }

.invalid {
  border-color: red; }

.errorMessage {
  color: red; }

.Toolbar {
  height: 56px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #343a40;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 90; }

.Toolbar nav {
  height: 100%; }

.Logo {
  height: 80%; }

@media (max-width: 499px) {
  .DesktopOnly {
    display: none; } }
.Logo {
  padding: 8px;
  height: 100%;
  box-sizing: border-box;
  border-radius: 5px; }

.Logo img {
  height: 100%; }

.NavigationItems {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%; }

@media (min-width: 500px) {
  .NavigationItems {
    flex-flow: row; } }
.NavigationItem {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%; }

.NavigationItem a {
  color: grey;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block; }

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
  color: #40A4C8; }

@media (min-width: 500px) {
  .NavigationItem {
    margin: 0px;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center; }

  .NavigationItem a {
    color: white;
    height: 100%;
    padding: 16px 10px;
    border-bottom: 4px solid transparent; }

  .NavigationItem a:hover,
  .NavigationItem a:active,
  .NavigationItem a.active {
    background-color: darkgray;
    border-bottom: 4px solid #ef4e2a;
    color: white; } }
.SideDrawer {
  background-color: #343a40;
  width: 56px;
  height: 100vh;
  float: left;
  position: fixed; }
  .SideDrawer .SideDrawerItems {
    display: none; }

.SideDrawer:hover {
  width: min-content; }
  .SideDrawer:hover main {
    max-width: 95%; }
  .SideDrawer:hover .DrawerToggle {
    padding-top: 56px; }
  .SideDrawer:hover .Circle {
    display: none; }
  .SideDrawer:hover .SideDrawerItems {
    display: block; }
  .SideDrawer:hover .DrawerToggle {
    width: 0px; }

.DrawerToggle {
  padding-top: 64px; }

.Circle {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  border: 1px solid white;
  padding-top: 7px;
  margin: auto; }

.Circle div {
  width: 25px;
  height: 1px;
  background-color: white;
  margin: auto;
  margin-top: 5px; }

.SideDrawerItems {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  flex-flow: column;
  align-items: center;
  height: 50px; }

@media (min-width: 500px) {
  .SideDrawerItems {
    flex-flow: row; } }
.SideDrawerItem {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%; }

.SideDrawerItem a {
  color: grey;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block; }

.SideDrawerItem a:hover,
.SideDrawerItem a:active {
  color: #40A4C8; }

@media (min-width: 500px) {
  .SideDrawerItem {
    margin: 0px;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center; }

  .SideDrawerItem a {
    color: white;
    height: 100%;
    padding: 16px 10px;
    border-bottom: 4px solid transparent; }

  .SideDrawerItem a:hover,
  .SideDrawerItem a:active {
    background-color: darkgray;
    border-bottom: 4px solid #ef4e2a;
    color: white; } }
